

:root {
  --primary-color: #FBAD18; /* default color */
  --secondary-color: #FBAD18; /* default color */
}

body {
  overflow-y: hidden; 
  overflow-x: hidden; 
}

.booking-component {
  font-family: Arial, sans-serif;
  background: #F2F3F5;
  // padding: 20px;
}

h2 {
  // background-color: #f0a500;
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  text-align: center;
}

.selection-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow: scroll;
  max-height: 75vh;
  min-height: 75vh;
  overflow-x: hidden;
}

.date-picker, .time-picker, .addons-picker {
  flex: 1;
  margin: 20px;
  border-radius: 8px;
  padding: 10px;
}

.addons-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  .addons-right-side{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 75%;
  }
}
.time-picker{
  display: flex;
  flex-direction: column;
}

.time-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.time-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}

.time-inputs label {
  display: block;
  margin-right: 5px;
}

.time-selectors{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 2em;
}

.ampm-select {
  margin-left: 5px;
  border-radius: 4px;
  height: 40px;
}

.total-time {
  // align-self: flex-end;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // margin: 10px;
  // padding: 5px;
  // width: 25vw;
  display: flex;
  align-self: end;
  // width: 30vw;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  .total-hours{
     font-size: 15px;
     font-weight: 600;
  }
}

.total-amount {
  align-self: flex-end !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  padding: 5px;
  width: 25vw;
  background-color: rgba(251, 173, 24, 0.1607843137);
  .total-price{
    font-size: 15px;
    font-weight: 600;
  }
}

.addon {
  margin-bottom: 10px;
}

.stepper-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  
}

button {
  margin: 10px;
}

.date-container {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  width: 100%
}

.time-range-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 2px solid white;
  border-radius: 4px;
  width: 15em;
  // background-color: rgba(251, 173, 24, 0.52) ;
  // background-color: #f0a500;
  background-color: var(--primary-color);
  margin-top: 1rem;
  // opacity: 0.5;
}

// .summary {
//   display: flex;
//   flex: direct;
//   flex-direction: column;
//   justify-content: space-around;
//   height: 45vh;
// }

.summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  // border: 2px solid white;
  border-radius: 4px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2em;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  // border: 2px solid white;
  border-radius: 4px;
  width: 90%; 
  // margin-top: 1rem;
  margin-bottom: 1rem;
}

.summary {
  margin-top: 20px;
  margin-left: 2rem;
  // width: 60vw;
  align-self: flex-end;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  align-self: auto;
  width: 100%;
}

@media (min-width: 600px) {
  .summary-container {
    width: 90%; /* Centered and 90% width on larger screens */
  }
}


.summary-header {
  text-align: center;
  margin-bottom: 20px;
}

.summary-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  flex-direction: column;
}

.time-price {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0; 
}

.time-price span:first-child {
  margin-right: auto;
}

.special-request input {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  margin-top: 10px;
  color: #aaa;
}

.total {
  display: flex;
  align-self: end;
  // width: 30vw;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
}


